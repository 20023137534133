<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-autocomplete
            outlined
            flat
            dense
            background-color="white"
            append-icon="mdi-magnify"
            item-text="purchaseOrderNumber"
            item-value="id"
            v-model="id"
            :items="purchaseOrders"
            :rules="[v => !!v || 'PO is required']"
            label="PO"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="1">
          <v-btn color="primary" @click="getBom">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-expansion-panels focusable>
        <v-expansion-panel v-for="(item, i) in items" :key="i" class="my-3">
          <v-expansion-panel-header disable-icon-rotate>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-center">
                    Part#
                  </th>
                  <th class="text-center">
                    Description
                  </th>
                  <th class="text-center">
                    Qty
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(x, y) in item.bomDetails" :key="y">
                  <td class="text-center">{{ x.partNum }}</td>
                  <td>{{ x.partDesc }}</td>
                  <td class="text-center">{{ x.qty }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <template v-slot:actions>
              <v-icon color="primary">
                mdi-alert-circle
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headers"
              :items="item.items"
              dense
              hide-default-footer
              disable-pagination
              class="mt-3"
            >
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.priceOriginalPrice="{ item }">
                {{ formatPrice(item.priceOriginalPrice) }}
              </template>
              <template v-slot:item.priceIdr="{ item }">
                {{ formatPrice(item.priceIdr) }}
              </template>
              <template v-slot:body.append="{ items }">
                <tr>
                  <td colspan="9" class="text-right">TOTAL IDR</td>
                  <td class="rext-right">{{ formatPrice(totalPriceIdr(items)) }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "bom-detail",
  data() {
    return {
      id: 0,
      items: [],
      purchaseOrders: [],
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part#",
          align: "left",
          sortable: false,
          value: "partNum",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Part Name",
          align: "left",
          sortable: false,
          value: "partName",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Description",
          align: "left",
          sortable: false,
          value: "partDesc",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "MFR",
          align: "center",
          sortable: false,
          value: "mfr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "UM",
          align: "center",
          sortable: false,
          value: "um",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Qty",
          align: "center",
          sortable: false,
          value: "qty",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Curr",
          align: "center",
          sortable: false,
          value: "priceOriginalCurrency",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Price",
          align: "right",
          sortable: false,
          value: "priceOriginalPrice",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 1,
          text: "Price IDR",
          align: "right",
          sortable: false,
          value: "priceIdr",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    getBom() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("report/getBomDetail", this.id)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getPurchaseOrders() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("external/getSalePurchaseOrderAllV2")
        .then(response => {
          this.purchaseOrders = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    totalPriceIdr(items) {
      return items.map(x => x.priceIdr).reduce((x, y) => x + y);
    },
  },
  mounted() {
    this.getPurchaseOrders();
  },
};
</script>

<style></style>
